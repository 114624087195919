// compileCompressed: ../../css/libraries/$1.css
@import "../base";

// =============================================================================
//  Define Media Queries for onmediaqueries.js - !!! DO NOT TOUCH !!!
// =============================================================================

body::after{
  display:none !important;
}
// -- smartphone_portrait one_col_stack
@include media-breakpoint-only(xs){
  html { font-family: "small"; }
}
@include media-breakpoint-only(sm){
  html { font-family: "small"; }
}
// -- tablet_portrait one_col_vert
@include media-breakpoint-only(md){
  html { font-family: "medium"; }
}
// -- bigscreen three_col_grail
@include media-breakpoint-only(lg){
  html { font-family: "large"; }
}
// -- bigscreen three_col_grail
@include media-breakpoint-only(xl){
  html { font-family: "xlarge"; }
}
// -- bigscreen three_col_grail
@include media-breakpoint-up(xxl){
  html { font-family: "xxlarge"; }
}
